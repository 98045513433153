import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { IVatQuotesAdminSettings } from '../models';

@Injectable({
  providedIn: 'root',
})
export class VatQuotesAdminSettingsService {
  constructor(private http: HttpClient) {}

  load(placesId: number[]) {
    return this.http.get(
      `admin/vat_quotes?${generateParamArray('place_id', placesId)}`,
    );
  }

  loadSingleItem(itemId: number) {
    return this.http.get(`admin/vat_quotes?id=${itemId}`);
  }

  create(request: IVatQuotesAdminSettings) {
    return this.http.post('admin/vat_quote', request);
  }

  update({ id, ...request }: IVatQuotesAdminSettings) {
    return this.http.put(`admin/vat_quote/${id}`, request);
  }

  delete(id: number) {
    return this.http.delete(`admin/vat_quote/${id}`);
  }
}
